.education_container {
  background-color: white;
  overflow-x: hidden;
  h1 {
    text-align: left;
    color: black;
    padding: 20px 40px;
  }
}

.education_box_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;

  @media screen and (max-width: 960px) {
    justify-content: start;
    flex-wrap: wrap;
  }
}

.education_box {
  padding: 50px 40px;
  background-color: white;
  color: black;
  position: relative;
  width: 500px;
  overflow: hidden;

  &__title {
    background-image: linear-gradient(45deg, #1900ff, #00f7ff);
    box-shadow: 4px 4px 11px 0px #a8a8a8;
    margin-bottom: 0px;
    padding: 10px;
    color: white;
  }

  &__year {
    border-radius: 50%;
    padding: 33px;
    background-color: white;
    color: black;
    font-size: 18px;
    font-weight: bold;
    margin-right: 20px;
    position: relative;

    &__animated {
      position: absolute;
      top: 0px;
      left: 0px;
      background-color: black;
      opacity: 0.5;
      width: 0px;
      height: 0px;
      border-radius: 100%;
      z-index: -1;
      animation: circle_animated 3s linear infinite;
    }
  }

  &__description {
    color: black;
    padding: 10px;
    background-color: #efefef;
    width: 100%;
  }
}

@keyframes circle_animated {
  0% {
    width: 0px;
    height: 0px;
  }
  50% {
    width: 100px;
    height: 100px;
  }
  100% {
    width: 0px;
    height: 0px;
  }
}
