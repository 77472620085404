.other_education__container {
  background-color: black;
  color: white;
  h1 {
    text-align: left;
    padding: 20px 40px;
    color: white;
  }
}

.other_education__box__container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  row-gap: 20px;
  column-gap: 20px;
  padding: 20px 40px;

  @media screen and (max-width: 960px) {
    grid-template-columns: auto auto;
  }

  @media screen and (max-width: 720px) {
    grid-template-columns: 100%;
  }
}

.other_education__box {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid yellow;
  box-shadow: 6px 9px 0px yellow;
  margin-bottom: 20px;
}

.udemy {
  color: #a435f0;
  a {
    color: #a435f0;
  }
}

.ibm {
  color: #7da7f5;
  a {
    color: #7da7f5;
  }
}

.linkedin {
  color: #0a66c3;
  a {
    color: #0a66c3;
  }
}
