@use '../../assets/styles/variables' as variables;

.about__me {
  color: white;
  padding: 30px;
  text-align: justify;
  padding: 40px;
  width: 65%;
  display: flex;
  margin: 0 auto;
  align-items: center;

  @media screen and (max-width: variables.$laptop-small-screen) {
    flex-wrap: wrap;
    width: 80%;
    text-align: center;
    justify-content: center;
  }

  &__picture {
    width: 300px;
    border-radius: 100%;
  }

  &__intro {
    padding: 20px;

    &__social {
      display: flex;
      align-items: center;

      a,
      i {
        margin-right: 15px;
      }

      a {
        color: white;
      }
    }
  }
}

.linkedin {
  color: #269af3;
}

.email {
  color: #f8be42;
}

/* .about-container{
    padding-top: 70px;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
}

.about-me{
    color: white;
    padding: 30px;
    text-align: justify;
    width: 65%;
    display: flex;
    margin: 0 auto;
}

.me{
    width: 300px;
    height:auto;
    background-image: url(../../public/img/me.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.social-networks{
    display: flex;
    align-items: flex-end;
}

.social-networks i {
    font-size: 40px;
    margin: 0 10px;
}

.fa-linkedin {
    color: #269af3;
}

.fa-envelope {
    color: #f8be42;
}

.social-networks a{
    text-decoration: none;
    color: white;
}

@media screen and (max-width:1050px){
    .about-container{
        height: 100%;
    }

    .about-me{
        margin: 0 auto;
        width: 90%;
        flex-direction: column;
    }

    .about-me p{
        font-size: 30px;
    }

    .me{
        width: 100%;
        height: 400px;
    }
}

@media screen and (max-width:960px){
    .about-me p{
        font-size: 20px;
    }
}

@media screen and (max-width:600px){
    .about-me{
        padding-top: 100px;
        flex-direction: column;
    }

    .about-me h1{
        text-align: center;
    }

    .about-me p{
        font-size: 18px;
    }
}

@media screen and (max-width:420px){
    .about-me{
        width: 100%;
    }
}

@media screen and (max-width:375px){
    .about-me p {
        font-size: 15px;
    }
    
    .social-networks a {
        font-size: 10px;
    }

    .social-networks i{
        font-size: 30px;
    }
}

@media screen and (max-width:320px){
    .about-me p {
        font-size: 12px;
    }
}

@media screen and (max-height:500px){
    .about-me{
        padding-top: 30px;
    }
} */
