@import url('https://fonts.googleapis.com/css?family=Fira+Code&display=swap');

.welcome-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black;
    opacity: 1;
    width: 100%;
}

.phrase{
    width: 100%;
    color: white;
}

.phrase h1{
    font-size: 100px;
    font-family: 'Fira Code', monospace !important;
    font-weight: bold;
    position: relative;
}

.enter-button{
    text-align: center;
    position: relative;
}

.enter-button button{
    text-align: center;
    font-size: 30px;
    background-color: black;
    color: white;
    border:none;
    padding: 10px;
    opacity: 0;
}

.enter-button h4{
    opacity: 0;
    color: white;
}

.show-enter-button{
    opacity: 1 !important;
    transition: opacity 0.5s linear;
}

.enter-button button:hover{
    background-color: rgb(32, 32, 32);
    transition: background-color 0.5s linear;
}

.slash{
    animation: slash_char 0.8s infinite;
}

.fade-out{
    background-color: black;
    opacity: 0;
    transition: opacity 0.4s linear;
}

.fade-top{
    animation: fade_top 0.4s linear;
    -webkit-animation: fade_top 0.4s linear;
    -moz-animation: fade_top 0.4s linear;
}

.fade-down{
    animation: fade_down 0.4s linear;
    -webkit-animation: fade_down 0.4s linear;
    -moz-animation: fade_down 0.4s linear;
}


@keyframes fade_top{
    from{   
        top: 0px;
        opacity: 1;
    }
    to{
        top: -100px;
        opacity: 0;
    }
}

@keyframes fade_down{
    from{   
        bottom: 0px;
        opacity: 1;
    }
    to{
        bottom: -100px;
        opacity: 0;
    }
}

@keyframes slash_char{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@media screen and (max-width:1050px){
    .phrase h1{
        font-size: 70px;
    }
}

@media screen and (max-width:1024px){
    .phrase h1{
        font-size: 60px;
    }
}

@media screen and (max-width:960){

}

@media screen and (max-width:800px){

}

@media screen and (max-width:600px){
    .phrase h1{
        font-size: 50px;
    }
}

@media screen and (max-width:500px){
    .phrase h1{
        font-size: 60px;
    }
}

@media screen and (max-width:420px){
    .phrase h1{
        font-size: 34px;
    }
}

@media screen and (max-width:375px){
    .phrase h1{
        font-size: 30px;
    }
}

@media screen and (max-width:320px){
    .phrase h1{
        font-size: 25px;
    }
}