.skill {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: center;

  &__name {
    color: white;
    width: 150px;
    text-align: left;
  }

  &__bar__container {
    width: 80%;
    height: 30px;

    .skill__bar {
      height: 100%;
      background-image: linear-gradient(to right, #0eff00, #356184);
      border-radius: 20px;
    }
  }

  &__percentage {
    color: white;
  }
}
