.work {
  display: flex;
  flex-direction: column;

  h1 {
    color: white;
    text-align: left;
    padding: 20px 40px;
  }

  &__box {
    width: 100%;
    padding: 40px;
    height: auto;
    background-color: white;
    text-align: left;

    &--bg-white {
      background-color: white;
      color: black;
    }

    &--bg-black {
      background-color: black;
      color: white;
    }

    &--white {
      color: white;
    }

    &--black {
      color: black;
    }

    &__bullet {
      color: rgb(20, 196, 0);
    }
  }
}
