@use '../../assets/styles/variables' as variables;
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

.menu {
  &__desktop {
    position: fixed;
    z-index: 2;
    font-family: 'Anton', sans-serif;
    width: 100%;
    padding: 20px;
    color: white;
    background-color: black;
    display: block;

    @media screen and (max-width: variables.$tablet-screen) {
      display: none;
    }

    ul {
      display: flex;
      justify-content: space-evenly;
      list-style: none;
      padding: 0px;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: bold;

      li {
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    a {
      color: white;
      &:hover {
        cursor: pointer;
      }
    }

    li.active {
      text-decoration: underline;
    }
  }

  &__mobile {
    font-family: 'Anton', sans-serif;
    background-color: transparent;
    position: fixed;
    z-index: 2;
    width: 100%;
    z-index: 1;
    display: none;
    border: 1px solid black;

    &__button {
      background-image: url('../../assets/img/menu-icon.svg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      border: none;
      background-color: transparent;
      position: relative;
      top: 16px;
      left: 0%;
      transition: left 1s linear;
    }

    @media screen and (max-width: variables.$tablet-screen) {
      display: block;
    }

    ul {
      list-style: none;
      padding: 0px;

      li {
        font-size: 32px;
        margin-bottom: 20px;
        font-weight: bold;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }

        a {
          color: black;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    li.active {
      text-decoration: underline;
    }
  }

  &__mobile__show {
    background-color: white;
    position: fixed;
    padding: 5px;
    height: 100%;
    animation: menu_open;
    animation-duration: 1s;

    ul {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s, opacity 1.5s;
    }

    button {
      background-image: url('../../assets/img/close_icon.svg');
      left: 40%;
      transition: left 1s linear;
    }
  }

  &__mobile__hidden {
    height: 70px;
    width: 70px;
    border-radius: 100%;
    background-color: white;
    overflow: hidden;
    animation: menu_close;
    animation-duration: 1s;

    ul {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 1s;
    }
  }
}

@keyframes menu_open {
  from {
    height: 70px;
    width: 70px;
    border-radius: 100%;
  }
  to {
    width: 100%;
    height: 100%;
    border-radius: 0%;
  }
}

@keyframes menu_close {
  from {
    width: 100%;
    height: 100%;
    border-radius: 0%;
  }
  to {
    height: 70px;
    width: 70px;
    border-radius: 100%;
  }
}
