.contact_container {
  color: black;
  background-color: white;
  height: 100%;
  h1 {
    text-align: left;
    color: black;
    padding: 20px 40px;
  }

  p {
    font-size: 1.5rem;
  }

  &__information {
    padding: 20px 40px;
  }
}
