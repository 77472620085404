.skills {
  padding: 20px 40px;

  h1{
    color: white;
    text-align: left;
  }
}

.skills__container {
  padding-top: 20px;
}
